import PropTypes from 'prop-types' 
import React from 'react'
import { connect } from 'react-redux'

import Me from '../../modules/me'

class MeWrapper extends React.Component {
    componentDidMount() {
        const {
            accessToken,
            currentUser,
            getCurrentUser,
        } = this.props

        if (!!accessToken && !currentUser) {
            getCurrentUser()
        }
    }

    render() {
        const { accessToken, children, currentUser } = this.props

        if (!accessToken) {
            return children
        }

        if (!!accessToken && !currentUser) {
            return null
        }

        return React.cloneElement(children, {
            currentUser,
            ...this.props,
        })
    }
}

Me.defaultProps = {
    landingPage: null,
}

Me.propTypes = {
    landingPage: PropTypes.object,
    getLandingPage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    currentUser: Me.selectors.getCurrentUser(state),
})

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: (payload) => (
        dispatch(Me.creators.getCurrentUser({
            payload,
        }))
    )
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    getCurrentUser: () => {
        dispatchProps.getCurrentUser({
            publisherId: ownProps?.publisherId,
        })
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(MeWrapper)
