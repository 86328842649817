
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[company_slug]/pico-link",
      function () {
        return require("private-next-pages/[company_slug]/pico-link.jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[company_slug]/pico-link"])
      });
    }
  