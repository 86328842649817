import React from 'react'
import { useSelector, useStore } from 'react-redux'

import Account from 'modules/account'
import LandingPage from 'modules/landing-page'
import FeatureFlagsModule from 'modules/feature-flags'

import EnterEmail from 'containers/Login/EnterEmail'
import EnterEmailV2 from 'containers/Login/EnterEmailV2'

import withAuthRouter from 'containers/AuthRouter'

import isEqual from 'lodash/isEqual'

const InjectedEnterEmail = ({
    currentUser,
    router,
    landingPage,
}) => {
    const state = useStore().getState()
    const { query } = router || {}
    const {
        short_code: shortCode,
    } = query || {}

    const authenticationMethods = LandingPage.selectors.authenticationMethods(state, shortCode)

    const authConsolidation = useSelector(state => FeatureFlagsModule.selectors.getFeatureFlagIsEnabled(state, 'auth-consolidation'), isEqual)

    const loginEmail = useSelector(Account.selectors.loginEmail)

    if (!landingPage) {
        return null
    }

    const Component = authConsolidation ? EnterEmailV2 : EnterEmail

    console.log({authConsolidation})

    return (
        <Component
            currentUser={currentUser}
            router={router}
            authenticationMethods={authenticationMethods}
            landingPage={landingPage}
            loginEmail={loginEmail}
            isJoiningGroup={false}
            isPayingGroup={false}
        />
    )
}

export default withAuthRouter(React.memo(InjectedEnterEmail))
