import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'lib/RouterAdapterDecorator'

import WizardRedux from 'pkgs/WizardRedux'
import Access from '../Access'
import LandingPageContainer from '../LandingPage'
import Loader from '../Loader'

import MeWrapper from '../MeWrapper'

function withAuthRouter(WrappedComponent, { disableLoading = false } = {}) {
    const ConnectedAuthWrapper = connect((state, props) => {
        const { router, shortCodeRoute: short_code, creatorUserName, claim } = props

        return {
            ...props,
            disableLoading,
            accessToken: state._network.access_token[creatorUserName],
            refreshToken: state._network.refresh_token[creatorUserName],
            short_code,
            wizard: state.wizard,
            guestLoader: state.loader.guest,
            userLoader: state.loader.user,
            claim,
            state,
            ...(typeof window === 'undefined' && {
                router: {
                    ...router,
                    push: () => {},
                },
            })
        }
    })(AuthWrapper)

    return withRouter((props) => (
        <ConnectedAuthWrapper {...props}>
            <WrappedComponent />
        </ConnectedAuthWrapper>
    ))
}

const AuthWrapper = React.memo((props) => {
    const {
        disableLoading,
        accessToken,
        refreshToken,
        guestLoader,
        userLoader,
        router,
        children,
        dispatch,
        publisherId,
        wizard,
        client,
        short_code: shortCode,
        claim,
    } = props

    return (
        <Access
            accessToken={accessToken}
            refreshToken={refreshToken}
            router={router}
            disableLoading={disableLoading}
            claim={claim}
        >
            <LandingPageContainer
                router={router}
                client={client}
                guestLoader={guestLoader}
                userLoader={userLoader}
                shortCode={shortCode}
                publisherId={publisherId}
            >
                <WizardRedux.Verify
                    accessToken={accessToken}
                    router={router}
                    wizard={wizard}
                >
                    <Loader {...props}>
                        <MeWrapper
                            accessToken={accessToken}
                            publisherId={publisherId}
                        >
                            {React.cloneElement(children, {
                                ...props,
                                accessToken,
                                refreshToken,
                                guestLoader,
                                userLoader,
                                router,
                                dispatch,
                            })}
                        </MeWrapper>
                    </Loader>
                </WizardRedux.Verify>
            </LandingPageContainer>
        </Access>
    )
})

AuthWrapper.defaultProps = {
    accessToken: null,
    publisherId: null,
}

AuthWrapper.propTypes = {
    publisherId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    accessToken: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
}

export default withAuthRouter
