import PropTypes from 'prop-types' 
import React from 'react'
import { connect } from 'react-redux'

import Loader from '../../modules/loader'
import Newsletter from 'modules/newsletter'

class LoaderContainer extends React.Component {
    componentDidMount() {
        const {
            shortCode, guestLoader, userLoader, accessToken, refreshToken, dispatch, publisherId,
            isFetching,
        } = this.props

        if (isFetching) {
            return
        }

        if (!accessToken && !guestLoader && !userLoader && !refreshToken) {
            dispatch(Newsletter.creators.getNewsletters({
                payload: {
                    publisherId,
                },
            }))
            dispatch(Loader.creators.getLoader({
                shortCode,
                scope: 'guest',
                publisherId,
            }))
        } else if (accessToken && !userLoader) {
            dispatch(Loader.creators.getLoader({
                shortCode,
                scope: 'user',
                publisherId,
            }))
        }
    }

    componentDidUpdate(prevProps) {
        const {
            shortCode, guestLoader, userLoader, accessToken, refreshToken, dispatch, publisherId,
            isFetching, isError
        } = this.props

        if (accessToken && !userLoader && !isFetching && !isError) {
            dispatch(Loader.creators.getLoader({
                shortCode,
                scope: 'user',
                publisherId,
            }))
        }

        if (!accessToken && !refreshToken) {
            if (!isFetching) {
                dispatch(Loader.creators.removeLoader({
                    scope: 'user',
                }))
            }

            if (!guestLoader && (prevProps.userLoader !== userLoader) && !isFetching) {
                dispatch(Loader.creators.getLoader({
                    shortCode,
                    scope: 'guest',
                    publisherId,
                }))
            }
        }
    }

    render() {
        const { guestLoader, userLoader, children } = this.props

        return React.cloneElement(children, {
            guestLoader,
            userLoader,
        })
    }
}

LoaderContainer.defaultProps = {
    accessToken: null,

    publisherId: null,
}

LoaderContainer.propTypes = {
    publisherId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    accessToken: PropTypes.string,
}

export default connect(
    (state) => ({
        isFetching: state?.loader?.isFetching,
        isError:state?.loader?.isError
    })
)(LoaderContainer)
