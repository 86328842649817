import PropTypes from 'prop-types' 
import React from 'react'
import { connect } from 'react-redux'

import { SET_SCOPE } from 'piconetworks/pkg-network-middleware'
import Loading from 'piconetworks/pkg-loading'
import Auth from '../../modules/auth'
import Utils from '../../modules/utils'
import Claim from 'modules/claim'

class Access extends React.Component {
    componentDidMount() {
        const {
            accessToken, refreshToken, router, dispatch, claim
        } = this.props

        const scope = router.query.scope || this.props.scope

        if (scope) {
            dispatch({ type: SET_SCOPE, scope })
        }

        if (claim) {
            // console.log('Apps.creators.logoutAndExchangeClaim({ claim })', )
            dispatch(Utils.creators.logoutAndExchangeClaim({ payload: { claim } }))
        }

        if (router.query.claim) {
            dispatch(Auth.creators.claimMagicLink({ claim: router.query.claim, scope }))

        } else if (!accessToken && refreshToken) {
            dispatch(Auth.creators.useRefreshToken())
        }
    }

    render() {
        const {
            accessToken, refreshToken, router, disableLoading, children,
            claimModule, isAuthed,
        } = this.props

        const magicLinkClaimInProgress = claimModule && !isAuthed

        if (!disableLoading
            && (
                (router.query.claim && !refreshToken)
              || (!accessToken && refreshToken)
              || magicLinkClaimInProgress
            )
        ) {
            return <Loading />
        }

        return React.cloneElement(children, {
            accessToken,
            refreshToken,
        })
    }
}

const mapStateToProps = ({ auth }) => ({
    claimModule: auth.claim,
    isAuthed: auth.isAuthed,
})

Access.defaultProps = {
    accessToken: null,
}

Access.propTypes = {
    dispatch: PropTypes.func.isRequired,
    accessToken: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
}

export default connect(mapStateToProps)(Access)
